import { InteractionType, RedirectRequest } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { LocaleProvider } from 'baseui';
import { Block } from 'baseui/block';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ApiException } from '@@api/api.generated';
import { appConfig } from '@@config/appConfig';
import { reactPlugin } from '@@config/appInsights';
import { MainNavigation } from '@@core/MainNavigation';
import { Unauthorized } from '@@core/pages/Unauthorized';
import { DebugOnly } from '@@shared/DateAndLocaleInfoDebug';
import { TimeZoneContextProvider } from '@@shared/TimeZoneContext';
import { SpinnerOverlay } from '@@shared/basewebComponentOverrides/Spinner';
import { useLayoutQuery } from '@@shared/hooks/useLayoutQuery';
import { useUser } from '@@shared/hooks/useUser';
import { BreakpointHelper } from '@@shared/utilityComponents/BreakpointHelper';
import { useAppRoutes } from './Routes';

function AppAuthenticatedTemplate({ children }: { children: React.ReactNode }) {
  const { instance } = useMsal();
  const account = instance.getActiveAccount() || instance.getAllAccounts()[0];

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={{
          scopes: ['openid', appConfig.apiAuthConfig],
        }}
      >
        {children}
      </MsalAuthenticationTemplate>
      <UnauthenticatedTemplate>
        <>
          {() => {
            instance.loginRedirect({
              account,
              scopes: ['openid', appConfig.apiAuthConfig],
              forceRefresh: false,
              redirectUri: `${window.location.origin}${appConfig.publicUrl}`,
            } as RedirectRequest);
            return <p>Redirecting...</p>;
          }}
        </>
      </UnauthenticatedTemplate>
    </>
  );
}

function AppAuthorizedTemplate({ children }: { children: ReactNode }) {
  const userQuery = useUser(true);

  const { languageCode, callCenterTimeZone } = userQuery;

  const { i18n } = useTranslation();

  const [baseWebLocale, setBaseWebLocale] = useState(i18n.getResourceBundle(i18n.language, 'baseweb'));

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (userQuery.isSuccess) {
      if (i18n.language !== languageCode) {
        i18n.changeLanguage(languageCode).finally(() => {
          setBaseWebLocale(i18n.getResourceBundle(languageCode, 'baseweb'));

          setInitialized(true);
        });
      } else {
        setInitialized(true);
      }
    }
  }, [i18n, languageCode, userQuery.isSuccess]);

  if (userQuery.isError) {
    const error = userQuery.error;

    if (ApiException.isApiException(error) && (error.status === 401 || error.status === 403)) {
      return <Unauthorized />;
    } else {
      throw error; // Let the error boundary deal with it
    }
  } else if (userQuery.isLoading || !initialized) {
    return <SpinnerOverlay />;
  }

  return (
    <TimeZoneContextProvider timeZone={callCenterTimeZone}>
      <LocaleProvider locale={baseWebLocale}>{children}</LocaleProvider>
    </TimeZoneContextProvider>
  );
}

function AppRoutes() {
  const appRoutes = useAppRoutes();

  return (
    <Routes>
      {appRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            route.userHasAccess ? (
              <AppInsightsErrorBoundary
                key={route.path}
                onError={() => <h1>Something went wrong, try reloading. If the problem persists, contact IT.</h1>}
                appInsights={reactPlugin}
              >
                {route.component}
              </AppInsightsErrorBoundary>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
      ))}
    </Routes>
  );
}

function App() {
  const isBigLayout = useLayoutQuery();

  return (
    <AppInsightsErrorBoundary
      onError={() => <pre>Something went very wrong, try reloading. If the problem persists, contact IT.</pre>}
      appInsights={reactPlugin}
    >
      <AppAuthenticatedTemplate>
        <AppAuthorizedTemplate>
          <Block display="flex" flexDirection="column" height="100%">
            <MainNavigation />

            <Block
              padding={`0 ${isBigLayout ? 32 : 8}px`}
              backgroundColor="backgroundPrimary"
              display="flex"
              flexDirection="column"
              flex="1 1 auto"
              minHeight="0"
            >
              <AppRoutes />
            </Block>
          </Block>
          <DebugOnly>
            <BreakpointHelper />
          </DebugOnly>
        </AppAuthorizedTemplate>
      </AppAuthenticatedTemplate>
    </AppInsightsErrorBoundary>
  );
}

export default App;
