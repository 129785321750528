import { useStyletron } from 'baseui';
import { StatefulPanel } from 'baseui/accordion';
import { Button, KIND } from 'baseui/button';
import { ButtonGroup } from 'baseui/button-group';
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from 'baseui/checkbox';
import { Select, TYPE, Value } from 'baseui/select';
import {
  Children,
  cloneElement,
  Dispatch,
  FormEvent,
  isValidElement,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { MdAdd, MdLocationOn, MdRemove } from 'react-icons/md';
import { RentalCarProviderDto } from '../api/api.generated';
import { useRentalCarProvidersQuery } from '../api/queries/useRetailerQueries';
import {
  DEFAULT_MAP_COORDS,
  DEFAULT_MAP_ZOOM,
  DEFAULT_MAP_ZOOM_NO_HITS,
  IconTypes,
  IMarker,
  LatLngCoords,
} from './Map';
import { MAP_SETTINGS } from './MapSettings';
import { LatLng, LatLngLiteral } from './types';
import { useDeepCompareEffectForMaps } from './useDeepCompareEffectForMaps';

interface Props extends google.maps.MapOptions {
  children?: ReactNode;
  toggleMapFilter: (types: IconTypes[], event: FormEvent<HTMLInputElement>) => void;
  mapFilters: IconTypes[];
  incident: IMarker[];
  incidentLat: number;
  incidentLng: number;
  carRentals: IMarker[];
  workshops: IMarker[];
  zoom: number;
  setZoom: Dispatch<SetStateAction<number>>;
  onIdle: (m: google.maps.Map) => void;
  setCarRentalFilter: Dispatch<SetStateAction<string | undefined>>;
}

const InnerMapComp = (props: Props) => {
  const {
    mapFilters,
    toggleMapFilter,
    children,
    incident,
    carRentals,
    workshops,
    center,
    zoom,
    setZoom,
    onIdle,
    setCarRentalFilter,
    incidentLat,
    incidentLng,
    ...options
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const [carRentalProviders, setCarRentalProviders] = useState<Value>([]);

  const [css] = useStyletron();
  const { t } = useTranslation('translation', { keyPrefix: 'map' });

  const rentalCarProviders = useRentalCarProvidersQuery();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          ...MAP_SETTINGS,
          center,
          zoom: zoom,
        })
      );
    }
  }, [ref, map, zoom, center]);

  useEffect(() => {
    if (!map || !center) return;

    let newCenter: LatLngCoords;

    if (typeof center.lat === 'function') {
      newCenter = { lat: (center as LatLng).lat(), lng: (center as LatLng).lng() };
    } else {
      newCenter = { lat: (center as LatLngLiteral).lat, lng: (center as LatLngLiteral).lng };
    }
    if (newCenter.lat === DEFAULT_MAP_COORDS.lat && newCenter.lng === DEFAULT_MAP_COORDS.lng) {
      map.setZoom(DEFAULT_MAP_ZOOM_NO_HITS);
      map.setCenter(newCenter);
    }
  }, [center, map]);

  const zoomToFitIncident = useCallback(() => {
    if (incidentLng && incidentLat && map) {
      map.setCenter({ lng: incidentLng, lat: incidentLat } as LatLngLiteral);
      map.setZoom(DEFAULT_MAP_ZOOM);
      setZoom(DEFAULT_MAP_ZOOM);
    }
  }, [incidentLat, incidentLng, map, setZoom]);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  const zoomMap = (way: 'in' | 'out') => {
    if (map) {
      switch (way) {
        case 'in':
          map.setZoom(zoom + 1);
          setZoom(zoom + 1);
          break;
        case 'out':
          map.setZoom(zoom - 1);
          setZoom(zoom - 1);
          break;
      }
    }
  };

  useEffect(() => {
    zoomToFitIncident();
  }, [zoomToFitIncident]);

  const checkIfDisabled = (type: IMarker[][]): boolean => {
    return type.every((t) => t.length === 0);
  };

  useEffect(() => {
    if (map) {
      ['idle'].forEach((eventName) => google.maps.event.clearListeners(map, eventName));

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onIdle]);

  return (
    <div className={css({ position: 'absolute', top: '0', right: '0', bottom: '0', left: '0' })}>
      <div ref={ref} id="map" style={{ height: '100%' }} />
      {Children.map(children, (child: ReactNode) => {
        if (isValidElement(child)) {
          // set the map prop on the child component
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return cloneElement<any>(child, { map });
        }
      })}
      <ButtonGroup
        kind={KIND.primary}
        overrides={{ Root: { style: { position: 'absolute', bottom: '30px', left: '30px' } } }}
      >
        {[
          <Button
            key="0"
            type="button"
            onClick={() => {
              zoomToFitIncident();
            }}
            disabled={checkIfDisabled([incident])}
          >
            <MdLocationOn />
          </Button>,
        ]}
      </ButtonGroup>
      <ButtonGroup
        kind={KIND.primary}
        overrides={{ Root: { style: { position: 'absolute', bottom: '30px', right: '30px' } } }}
      >
        <Button
          type="button"
          onClick={() => {
            zoomMap('in');
          }}
        >
          <MdAdd />
        </Button>
        <Button
          type="button"
          onClick={() => {
            zoomMap('out');
          }}
        >
          <MdRemove />
        </Button>
      </ButtonGroup>
      <ul className={css({ position: 'absolute', top: '0', left: '0', padding: '0', margin: '0' })}>
        <StatefulPanel
          title={t('mapFiltering')}
          overrides={{
            ToggleIcon: { style: { marginLeft: '10px' } },
            Content: {
              style: { paddingLeft: '16px', paddingRight: '16px', paddingTop: '16px', paddingBottom: '16px' },
            },
          }}
        >
          <Checkbox
            checked={
              mapFilters.includes(IconTypes.WORKSHOP) &&
              mapFilters.includes(IconTypes.CAR_RENTAL) &&
              //mapFilters.includes(IconTypes.CHARGINGSTATION) &&
              mapFilters.includes(IconTypes.INCIDENT)
            }
            checkmarkType={STYLE_TYPE.toggle_round}
            onChange={(e) => toggleMapFilter([IconTypes.WORKSHOP, IconTypes.INCIDENT, IconTypes.CAR_RENTAL], e)}
            labelPlacement={LABEL_PLACEMENT.right}
            overrides={{ Root: { style: { borderBottom: '2px solid #E2E2E2', padding: '10px 0' } } }}
            disabled={checkIfDisabled([workshops, incident, carRentals])}
          >
            {t('allFilters')}
          </Checkbox>
          <Checkbox
            checked={mapFilters.includes(IconTypes.INCIDENT)}
            checkmarkType={STYLE_TYPE.toggle_round}
            onChange={(e) => toggleMapFilter([IconTypes.INCIDENT], e)}
            labelPlacement={LABEL_PLACEMENT.right}
            overrides={{ Root: { style: { borderBottom: '2px solid #E2E2E2', padding: '10px 0' } } }}
            disabled={checkIfDisabled([incident])}
          >
            {t('incidentAddress')}
          </Checkbox>
          <Checkbox
            checked={mapFilters.includes(IconTypes.WORKSHOP)}
            checkmarkType={STYLE_TYPE.toggle_round}
            onChange={(e) => toggleMapFilter([IconTypes.WORKSHOP], e)}
            labelPlacement={LABEL_PLACEMENT.right}
            overrides={{ Root: { style: { padding: '10px 0' } } }}
            disabled={checkIfDisabled([workshops])}
          >
            {t('workshops')}
          </Checkbox>
          <Checkbox
            checked={mapFilters.includes(IconTypes.CAR_RENTAL)}
            checkmarkType={STYLE_TYPE.toggle_round}
            onChange={(e) => toggleMapFilter([IconTypes.CAR_RENTAL], e)}
            labelPlacement={LABEL_PLACEMENT.right}
            overrides={{ Root: { style: { borderBottom: '2px solid #E2E2E2', padding: '10px 0' } } }}
            disabled={checkIfDisabled([carRentals])}
          >
            {t('carRental')}
          </Checkbox>
          {/*<Checkbox*/}
          {/*  checked={mapFilters.includes(IconTypes.CHARGINGSTATION)}*/}
          {/*  checkmarkType={STYLE_TYPE.toggle_round}*/}
          {/*  onChange={(e) => toggleMapFilter([IconTypes.CHARGINGSTATION], e)}*/}
          {/*  labelPlacement={LABEL_PLACEMENT.right}*/}
          {/*  overrides={{ Root: { style: { padding: '10px 0' } } }}*/}
          {/*>*/}
          {/*  {t('chargingStations')}*/}
          {/*</Checkbox>*/}
          <Select
            options={rentalCarProviders.data}
            placeholder={t('carRentalFilter')}
            maxDropdownHeight={'300px'}
            labelKey="name"
            isLoading={rentalCarProviders.isLoading}
            error={rentalCarProviders.isLoadingError}
            disabled={rentalCarProviders.isLoading}
            type={TYPE.search}
            onChange={({ value }) => {
              if (value.length > 0) {
                const carRentalFilter = value[0] as RentalCarProviderDto;
                setCarRentalFilter(carRentalFilter.id);
              } else {
                setCarRentalFilter(undefined);
              }
              setCarRentalProviders(value);
            }}
            value={carRentalProviders}
          />
        </StatefulPanel>
      </ul>
    </div>
  );
};

export const InnerMap = memo(InnerMapComp);
