import { useQuery } from '@tanstack/react-query';
import { ComboboxItem } from '../api.generated';
import { useApiClient } from '../apiClientContext';

/** Queries */

export const useIncidentCodesQuery = () => {
  const { caseClient } = useApiClient();

  return useQuery(['IncidentCodes'], async () => await caseClient.getIncidentCodes());
};

export const useIncidentDetailCodesQuery = (incidentCodeId: ComboboxItem['id']) => {
  const { caseClient } = useApiClient();

  const queryKey = ['IncidentDetailCodes', incidentCodeId ?? ''];

  return useQuery(queryKey, async () => await caseClient.getIncidentDetailCodes(incidentCodeId ?? ''), {
    enabled: !!incidentCodeId,
  });
};

export const useVehicleBrandsQuery = () => {
  const { caseClient } = useApiClient();

  return useQuery(['VehicleBrands'], async () => await caseClient.getVehicleBrands());
};

export const useVehicleModelsQuery = (brandId: ComboboxItem['id']) => {
  const { caseClient } = useApiClient();

  const queryKey = ['VehicleModels', brandId ?? ''];

  return useQuery(
    queryKey,
    async () => {
      return await caseClient.getModels(brandId ?? '');
    },
    { enabled: !!brandId }
  );
};

export const useDriveTrainsQuery = () => {
  const { caseClient } = useApiClient();

  return useQuery(['DriveTrains'], async () => await caseClient.getDriveTrains());
};

export const useFuelsQuery = () => {
  const { caseClient } = useApiClient();

  return useQuery(['Fuels'], async () => await caseClient.getFuels());
};

export const useVehicleTypesQuery = () => {
  const { caseClient } = useApiClient();

  return useQuery(['VehicleTypes'], async () => await caseClient.getVehicleTypes());
};

export const useHomeAwayQuery = () => {
  const { caseClient } = useApiClient();

  return useQuery(['HomeAway'], async () => await caseClient.getHomeAway());
};

export const useCustomerWaiting = () => {
  const { caseClient } = useApiClient();

  return useQuery(['CustomerWaiting'], async () => await caseClient.getCustomerWaiting());
};

export const useInsuranceTypesQuery = () => {
  const { caseClient } = useApiClient();

  return useQuery(['InsuranceTypes'], async () => await caseClient.getInsuranceTypes());
};

export const useCancellationBasesQuery = () => {
  const { caseClient } = useApiClient();

  return useQuery(['CancellationBases'], async () => await caseClient.getCancellationBases());
};

export const useOriginCodesQuery = () => {
  const { caseClient } = useApiClient();

  return useQuery(['OriginCodes'], async () => await caseClient.getOriginCodes());
};

export const useInsuranceStatusCodesQuery = () => {
  const { caseClient } = useApiClient();

  return useQuery(['InsuranceStatusCodes'], async () => await caseClient.getInsuranceStatusCodes());
};

export const useKeyPlacementCodesQuery = () => {
  const { caseClient } = useApiClient();

  return useQuery(['KeyPlacementCodes'], async () => await caseClient.getKeyPlacementCodes());
};

export const useInfoCallCodesQuery = () => {
  const { caseClient } = useApiClient();

  return useQuery(['InfoCallCodes'], async () => await caseClient.getInfoCallTexts());
};

export const useLoggTypesQuery = () => {
  const { caseClient } = useApiClient();

  return useQuery(['LoggTypes'], async () => await caseClient.getLoggTypes());
};

export const useServicesQuery = (enabled = true) => {
  const { caseClient } = useApiClient();

  return useQuery(['Services'], async () => await caseClient.getServices(), { enabled });
};

export const useStatusesQuery = () => {
  const { caseClient } = useApiClient();

  return useQuery(['Statuses'], async () => await caseClient.getCaseStatuses());
};

export const useDeadlineTypes = (enabled = true) => {
  const { caseClient } = useApiClient();

  return useQuery(['Deadlinetypes'], async () => await caseClient.getDeadlineTypes(), {
    enabled,
  });
};

export const useSmsTemplates = () => {
  const { caseClient } = useApiClient();

  return useQuery(['SmsTemplates'], async () => await caseClient.getSmsTemplates());
};

export const useGroupsQuery = (enabled = true) => {
  const { caseClient } = useApiClient();

  return useQuery(['Groups'], async () => await caseClient.getGroups(), { enabled });
};

export const usePostalAreaQuery = (countryId: string, postalCode: string, enabled = true) => {
  const { caseClient } = useApiClient();

  // Swedish postal codes have space, this needs to be removed to map out from postalCodes-list
  postalCode = postalCode ? postalCode.split(' ').join('') : '';

  return useQuery(
    ['PostalArea', countryId, postalCode],
    async () => await caseClient.getPostalArea(countryId, postalCode),
    // TODO remove retry: false after API stops responding with http 500 for "invalid" postal codes
    { enabled: enabled && !!postalCode && !!countryId, retry: false }
  );
};

export const useGetDeadlineQuery = () => {
  const { caseClient } = useApiClient();

  const queryKey = ['IncidentDeadlines'];

  return useQuery(queryKey, async () => await caseClient.getCaseDeadlines2());
};
