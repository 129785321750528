import { Block } from 'baseui/block';
import { OnChangeParams } from 'baseui/select';
import { useFormikContext } from 'formik';
import { memo, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useEvent from 'react-use-event-hook';
import { getLatLng } from 'use-places-autocomplete';
import { Item } from '@@api/api.generated';
import { useApiClient } from '@@api/apiClientContext';
import { asKeyOf } from '@@caseRegistration/caseRegistrationFunctions';
import { getPostalAreaFromGeocodeResult, resolveAddressWithNewPostalCode } from '@@map/placesFunctions';
import { AddressSearch, ResolvedAddress } from '@@shared/inputs/AddressSearch';
import { useCountriesQuery } from '../../api/queries/useActorQueries';
import { usePostalAreaQuery } from '../../api/queries/useCaseMasterDataQueries';
import { Input } from '../../shared/basewebComponentOverrides/Input';
import { FastInputField as InputField } from '../../shared/formFields/InputField';
import { FastSelectField as SelectField } from '../../shared/formFields/SelectField';
import { useUser } from '../../shared/hooks/useUser';
import { CaseRegistrationFormFields } from '../caseRegistrationModels';

interface Props {
  mapping: string;
  allowSearch?: boolean;
  readOnly?: boolean;
}

enum AddressObject {
  Incident = 'incident',
  Workshop = 'workshop',
}

function LocationFormComp({ mapping, allowSearch, readOnly }: Props) {
  const { values, setValues } = useFormikContext<CaseRegistrationFormFields>();

  const setFormValues = useCallback(
    (newValues: Partial<CaseRegistrationFormFields>) => {
      setValues((v) => ({ ...v, ...newValues }));
    },
    [setValues]
  );

  // 'mapping' prop is always static (i hope)
  const mappingRef = useRef(mapping);

  // And therefore so are the field names
  const postalCodeField = useRef(asKeyOf(values, mappingRef.current + 'AddressPostalCode'));
  const cityField = useRef(asKeyOf(values, mapping + 'AddressCity'));
  const streetField = useRef(asKeyOf(values, mapping + 'AddressStreet'));
  const countryField = useRef(asKeyOf(values, mapping + 'AddressCountry'));
  const latitudeField = useRef(asKeyOf(values, mapping + 'AddressLatitude'));
  const longitudeField = useRef(asKeyOf(values, mapping + 'AddressLongitude'));

  const postalCode = values[postalCodeField.current] as string;
  const city = values[cityField.current] as string;
  const street = values[streetField.current] as string;
  const country = values[countryField.current] as string;
  const latitude = values[latitudeField.current] as string;
  const longitude = values[latitudeField.current] as string;

  const addressIsMissingCoordinates = mapping === AddressObject.Incident && (!latitude || !longitude);

  const { callCenterCountry } = useUser();

  const tShared = useTranslation('translation', { keyPrefix: 'shared' }).t;

  const { data: countries } = useCountriesQuery();

  const countryName = useMemo(
    () => (countries?.length ? countries.find((c) => c.id === country)?.name : ''),
    [countries, country]
  );

  const { caseClient } = useApiClient();

  const { data: postalAreaForPostalCode, isFetchedAfterMount: postalAreaIsFetched } = usePostalAreaQuery(
    country,
    postalCode
  );

  const isCityFieldReadOnly =
    !!city && (city.toLowerCase() === postalAreaForPostalCode?.name?.toLowerCase() || !postalAreaIsFetched);

  const handleAddressResolved = (address: ResolvedAddress | undefined) => {
    if (!address) return;

    const newValues: Partial<CaseRegistrationFormFields> = {
      [postalCodeField.current]: address.postalCode,
      [cityField.current]: address.city,
      [streetField.current]: address.street,
      [latitudeField.current]: address.latitude,
      [longitudeField.current]: address.longitude,
    };

    if (mappingRef.current === AddressObject.Incident) {
      newValues.incidentAddressComment = address.poiDescription ?? '';
    } else if (mappingRef.current === AddressObject.Workshop) {
      newValues.workshopOther = address.poiDescription ?? '';
    }

    setFormValues(newValues);
  };

  const handlePostalCodeChange = useEvent(async (newPostalCode: string | undefined) => {
    if (!newPostalCode) {
      setFormValues({
        [postalCodeField.current]: '',
        [cityField.current]: '',
        [latitudeField.current]: '',
        [longitudeField.current]: '',
      });
      return;
    }

    let newPostalArea: Item | undefined;

    try {
      newPostalArea = await caseClient.getPostalArea(country, newPostalCode);
    } catch {
      /* empty */
    }

    if (!newPostalArea?.name) {
      setFormValues({
        [postalCodeField.current]: newPostalCode,
        [cityField.current]: '',
        [latitudeField.current]: '',
        [longitudeField.current]: '',
      });
      return;
    }

    let newValues: Partial<CaseRegistrationFormFields> = {
      [postalCodeField.current]: newPostalCode,
      [cityField.current]: newPostalArea.name,
    };

    if (street) {
      const newAddress = await resolveAddressWithNewPostalCode(street, newPostalCode, newPostalArea.name, country);

      if (newAddress) {
        const newLatLng = getLatLng(newAddress);

        const newPostalArea = getPostalAreaFromGeocodeResult(newAddress);

        newValues = {
          ...newValues,
          [cityField.current]: newPostalArea,
          [latitudeField.current]: newLatLng.lat,
          [longitudeField.current]: newLatLng.lng,
        };
      } else {
        newValues = {
          ...newValues,
          [latitudeField.current]: '',
          [longitudeField.current]: '',
        };
      }
    }

    setFormValues(newValues);
  });

  const handleCountryChange = useEvent((params: OnChangeParams) => {
    const newCountryCode = params.value && params.value[0] ? params.value[0].id : '';

    if (!newCountryCode) return;

    const newValues: Partial<CaseRegistrationFormFields> = {
      [countryField.current]: newCountryCode,
      [postalCodeField.current]: '',
      [cityField.current]: '',
      [streetField.current]: '',
      [latitudeField.current]: '',
      [longitudeField.current]: '',
    };

    if (mappingRef.current === AddressObject.Incident) {
      newValues.incidentAddressComment = '';
    } else if (mappingRef.current === AddressObject.Workshop) {
      newValues.workshopOther = '';
    }

    setFormValues(newValues);
  });

  function handleInputClear() {
    setFormValues({
      [streetField.current]: '',
      [latitudeField.current]: '',
      [longitudeField.current]: '',
    });
  }

  return (
    <>
      <div>
        {allowSearch && !readOnly ? (
          <AddressSearch
            value={street}
            // The usePlacesAutocomplete hook doesn't recognize changes to the cache key input
            // which is based on country, so the component needs to be remounted when the country changes
            key={country ?? callCenterCountry}
            country={country ?? callCenterCountry}
            showMissingCoordsWarning={!!street && addressIsMissingCoordinates}
            onAddressResolved={handleAddressResolved}
            onInputClear={handleInputClear}
          />
        ) : (
          <InputField readOnly={readOnly} label={`${tShared('address')}*`} name={streetField.current} />
        )}
      </div>
      <div>
        <Block display="flex">
          <Block flex="0 0 6em">
            <InputField
              readOnly={readOnly}
              label={`${tShared('postalCode')}*`}
              name={postalCodeField.current}
              onChange={handlePostalCodeChange}
            />
          </Block>
          <Block flex="1" paddingLeft="scale200">
            <InputField
              readOnly={readOnly || isCityFieldReadOnly}
              label={`${tShared('postalTown')}*`}
              name={cityField.current}
            />
          </Block>
        </Block>
      </div>

      <div>
        {readOnly ? (
          <Input readOnly label={tShared('country')} value={countryName} />
        ) : (
          <SelectField
            label={tShared('country')}
            options={countries}
            name={countryField.current}
            onChange={handleCountryChange}
            clearable={false}
          />
        )}
      </div>
    </>
  );
}

export const LocationForm = memo(LocationFormComp);
