import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { AppConfig } from './appConfig';

// https://www.i18next.com/overview/typescript#argument-of-type-defaulttfuncreturn-is-not-assignable-to-parameter-of-type-xyz
// t function was always able to return null by default, but now the type actually reflects that.
// This forces it to return a placeholder or an empty string or something
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export function configureLocalization(appConfig: AppConfig): void {
  i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      lng: appConfig.defaultLanguage,
      fallbackLng: appConfig.fallbackLanguage,
      supportedLngs: ['en', 'nb', 'sv', 'da', 'fi'],
      ns: ['translation', 'baseweb'],
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
      backend: {
        loadPath: appConfig.cacheBustHash
          ? `${appConfig.publicUrl}/locales/{{lng}}/{{ns}}.${appConfig.cacheBustHash}.json`
          : `${appConfig.publicUrl}/locales/{{lng}}/{{ns}}.json`,
      },
      returnNull: false,
    });
}
